@import url('https://fonts.googleapis.com/css2?family=Nanum+Gothic:wght@400;700&family=Poppins:wght@200;300;400;500;600&display=swap&family=Playfair+Display:wght@400;600;700&display=swap');

*, :before, :after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  padding: 0;
  margin: 0;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: 300;
}

.light-theme {
  --main : #333;
  --secondary : #f9f9f9;
  --accent : #067A17;
  --light-grey : #d2d2d2;
  --background: #fff;
}

.dark-theme {
  --main : #f9f9f9;
  --secondary : #333;
  --accent : #42bd62;
  --light-grey : #bbbbbb;
  --background: #000;
}

.main-container {
  background-color: var(--secondary);
  color: var(--main);
  position: relative;
}

main {
  padding: 70px 10%;
}

u {
  border-bottom: 2px solid var(--secondary);
  text-decoration: none;
  font-weight: 500;
  transition: all 500ms ease-in-out;
}

u:hover {
  border-color: var(--accent);
}

a {
  text-decoration: none;
}

@media screen and (max-width: 850px) {
  main {
    padding: 70px 5%;
  }
}