.popup-container {
    position: fixed;
    background: #00000050;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .popup-box {
    width: 70%;
    height: 90vh;
    overflow: scroll;
    background-color: var(--background);
    padding: 35px;
    display: flex;
    flex-direction: column;
  }
  .popup-close {
    align-self: flex-end;
    margin-bottom: 20px;
    cursor: pointer;
  }

  .popup-close img {
    height: 30px;
    width: 30px;
  }

  .popup-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 0;
  }

  .popup-heading h2 {
    color: var(--accent);
  }

  .popup-heading-stack {
    list-style-type: none;
    display: flex;
  }

  .popup-heading-stack img {
    width: 50px;
    height: 50px;
    border-radius: 50px;
    margin-left: 20px;
    padding: 5px;
    border: 1px solid var(--light-grey);
  }

  .projects-details-images {
    display: flex;
    justify-content: space-between;
    margin: 30px 0;
  }

  .projects-details-images img {
    width: 47%;
    height: 220px;
    object-fit: cover;
  }

  .projects-details-main {
    padding: 30px;
    background-color: var(--secondary);
  }

  .projects-details-main h3 {
    font-size: 18px;
    margin-bottom: 20px;
  }

  .projects-details-main ul {
    padding-left: 30px;
    margin: 0;
  }

  @media screen and (max-width: 850px) {

    .popup-box {
      width: 90%;
    }

    .popup-heading {
      flex-direction: column;
    }

    .popup-heading-stack {
      margin-top: 20px;
    }

    .projects-details-images {
      flex-direction: column;
    }

    .projects-details-images img {
      width: 100%;
    }

    .projects-details-images img:nth-child(1) {
      margin-bottom: 20px;
    }

    .popup-heading-stack img {
      width: 40px;
      height: 40px;
    }
  }