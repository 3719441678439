.project-card {
    display: flex;
    width: 100%;
    margin: 30px 0;
}

.project-card-reversed {
    flex-direction: row-reverse;
}

.project-see-more {
    cursor: pointer;
    color: var(--accent);
    margin-top: 20px;
    font-weight: 500;
}

.project-text {
    background-color: var(--background);
    width: 70%;
    padding: 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.project-text h3 {
    color: var(--accent);
}

.project-text p {
    font-size: 16px;
    margin-top: 5px;
}

.project-card img {
    width: 400px;
    height: 250px;
    object-fit: cover;
    filter: grayscale(1);
    transition: filter 400ms ease-in-out;
}

.project-card img:hover {
    filter: grayscale(0);
}

.project-tags ul {
    list-style-type: none;
    display: flex;
    margin-top: 20px;
}

.project-tags ul li {
    margin-right: 20px;
    font-family: 'Playfair Display', serif;
    font-size: 16px;
}

.project-tags a {
    text-decoration: none;
    color: var(--main);
}

img.project-github {
    width: 25px;
    height: 25px;
    margin-left: 30px;
}

img.project-github-inverted {
    filter: invert(100%);
}

img.project-github:hover {
    filter: invert(31%) sepia(97%) saturate(535%) hue-rotate(79deg) brightness(94%) contrast(105%);
}

img.project-github-inverted:hover {
    filter: invert(60%) sepia(12%) saturate(2049%) hue-rotate(83deg) brightness(102%) contrast(88%);
}

.material-symbols-outlined:hover {
    color: var(--accent);
}

@media screen and (max-width: 850px) {

    .project-card {
        flex-direction: column;
        position: relative;
        margin-bottom: 40px;
        height: fit-content;
    }

    .project-card > img {
        position: absolute;
        top: 0;
        opacity: 0.1; 
        height: 100%;
        width: 100%;
    }

    .project-text {
        width: 100%;
        height: fit-content;
        background: none;
        z-index: 1;
    }

    .project-name-desc {
        margin-bottom: 50px;
    }

}