.nav-selector {
    padding: 7px 20px;
    color: var(--main);
    cursor: pointer;
    transition: background-color 400ms ease-in-out;
}

.nav-selector:hover {
    background-color: var(--light-grey);
}

.language-select {
    margin-right: 30px;
}

.language-select img {
    width: 20px;
}

.theme-select {
    filter: grayscale(1);
}

img.profile-photo {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 100%;
  }

  header {
    padding: 25px 10%;
    background-color: var(--background);
  }
  
  header nav {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 400;
  }
  
  header nav li {
    margin: 0 20px;
  }

  .nav-all-links {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  
  .nav-anchors {
    list-style-type: none;
    display: flex;
    margin-right: 10px;
    padding-left: 30px;
    padding: 7px 0;
  }
  
  .nav-selectors {
    display: flex;
  }
  
  .nav-links a {
    color: var(--main);
    text-decoration: none;
  }
  
  .nav-burger {
    width: 40px;
    height: 40px;
    cursor: pointer;
    display: none;
  }

  .nav-burger-line {
    width: 40px;
    height: 3px;
    background-color: var(--main);
  }

  .nav-burger-one {
    margin-bottom: 12px;
  }

  .nav-burger-three {
    margin-top: 12px;
  }

  @media screen and (max-width: 850px) {

    header {
      position: relative;
      padding: 25px 5%;
    }

    header nav {
      justify-content: space-between;
    }

    .nav-all-links {
      position: absolute;
      right: 0;
      top: 100px;
      flex-direction: column;
      align-items: flex-end;
      display: none;
    }

    .nav-burger-open {
      display: flex;
      position: absolute;
      right: 0;
      top: 100px;
      z-index: 1;
    }

    .nav-anchors, .nav-selectors {
      margin-right: 0;
      flex-direction: column;
      width: 100%;
      padding-right: 5vw;
      background-color: var(--background);
      text-align: right;
    }

    .nav-links {
      width: 100%;
      font-size: 18px;
    }

    .nav-anchors {
      padding: 0 5vw 0 0;
    }

    .nav-selectors {
      padding-bottom: 30px;
      font-size: 18px;
    }

    .nav-selector {
      margin-right: 0;
      padding: 0;
      justify-content: flex-end;
    }

    .nav-selector:hover {
      background-color: transparent;
    }

    .nav-item {
      margin-right: 0;
    }

    .nav-burger {
      display: block;
    }

    .nav-burger-one, .nav-burger-three {
      transform-origin: 100%;
      transition: transform 400ms ease-in-out;
      transform: translateY(0) rotate(0deg);
    }

    .nav-burger-one-open {
      transform: rotate(-45deg) translateY(-9px);
      transition: transform 400ms ease-in-out;
    }

    .nav-burger-two-open {
      display: none;
    }

    .nav-burger-three-open {
      transform: rotate(45deg) translateY(10px);
      transition: transform 400ms ease-in-out;
    }
  }