.main-home {
    margin: 40px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.main-description h1 {
    font-size: 40px;
    color: var(--accent);
    font-weight: 400;
}

.main-description p:nth-child(2) {
    font-family: 'Playfair Display', serif;
    font-size: 30px;
}

.main-description p:nth-child(3) {
  margin-top: 30px;
}

.main-description span {
  cursor: pointer;
  display: inline-block;
}

.main-description span:hover {
  animation: bounce 500ms ease-in-out;
  color: #08a21e;
}

.main-about {
    margin: 40px auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

  .projects {
    position: relative;
    margin: 60px 0;
  }

  .section-top-border {
    position: relative;
    width: 60%;
    border-top: 1px solid var(--light-grey);
    margin-bottom: 60px;
  }

  .main-section {
    margin-bottom: 30px;
  }

  .main-section > h2 {
    font-family: 'Playfair Display', serif;
    font-size: 24px;
    width: fit-content;
    background-color: var(--secondary);
    z-index: 1;
    position: relative;
    top: 15px;
    font-weight: 600;
    padding-right: 40px;
  }

  .experience-container {
    display: flex;
  }

  .experience-selector ul {
    list-style-type: none;
    font-weight: 400;
  }

  .experience-selector li {
    margin: 5px 0;
  }

  .experience-item {
    font-weight: 300;
  }

  .experience-item-selected {
    font-weight: 500;
  }

  .experience-item-arrow {
    padding: 0 15px;
    color: var(--accent);
    transition: transform 500ms cubic-bezier(.51,.92,.24,1.15);
  }

  .experience-selector {
    flex: 1;
    cursor: pointer;
  }

  .experience-content {
    flex: 4;
    margin-left: 50px;
    min-height: 400px;
  }

  .experience-item-arrow-openclassrooms {
    transform: translateY(5px);
  }

  .experience-item-arrow-drivy {
    transform: translateY(30px);
  }

  .experience-item-arrow-schibsted {
    transform: translateY(60px);
  }

  .experience-item-arrow-leboncoin {
    transform: translateY(90px);
  }

  .resume-download {
    padding: 10px 35px;
    text-decoration: none;
    border: 1px solid var(--accent);
  }

  .about-container {
    display: flex;
  }

  .about-photo img {
    width: 200px;
    height: 200px;
    object-fit: cover;
    position: absolute;
    top: 20px;
    right: 20px;
  }

  .about-photo {
    position: relative;
    margin-left: 60px;
  }

  .about-photo-background {
    width: 200px;
    height: 200px;
    background-color: var(--main);
  }

  .about-content {
    margin: 10px 0;
  }

  .about-content p {
    margin: 15px 0;
  }

  .about-content-likes-block {
    display: flex;
    justify-content: space-between;
    margin: 40px 0 0 0;
  }

  .about-content-like {
    position: relative;
    padding: 30px 15px;
    background-color: var(--background);
    width: 45%;
  }

  .about-content-like ul {
    list-style-type: none;
  }

  .about-content-like ul > p {
    position: absolute;
    top: -30px;
    font-family: 'Playfair Display', serif;
    font-size: 20px;
    font-weight: 700;
  }

  .about-content-like:nth-child(2) {
    filter: grayscale(1);
  }

  .contact-cta {
    font-family: 'Playfair Display', serif;
    background-color: transparent;
    font-size: 16px;
    color: var(--accent);
    width: fit-content;
    display: flex;
    align-items: center;
    padding: 10px 35px;
    text-decoration: none;
    border: 1px solid var(--accent);
    margin-top: 40px;
    cursor: pointer;
  }

  .button:hover {
    background-color: var(--accent);
    transition: all 500ms ease-in-out;
    width: fit-content;
  }

  .button:hover a {
    color: var(--secondary);
  }

  .button:hover + .resume-download img {
    filter: brightness(1) grayscale(1);
  }

  .button {
    width: fit-content;
  }

  .button a {
    font-family: 'Playfair Display', serif;
    color: var(--accent);
  }

  .resume-download {
    background-color: transparent;
    font-size: 16px;
    width: fit-content;
  }

  @keyframes bounce {
    0% { transform: translateY(-5px); }
    50% { transform: translateY(2px); }
    100% { transform: translateY(0px); }
  }

  @media screen and (max-width: 850px) {

    .about-photo-background {
      display: none;
    }

    .about-container {
      flex-direction: column-reverse;
      align-items: center;
    }

    .about-photo img {
      position: static;
    }

    .experience-container {
      flex-direction: column;
    }

    .experience-item-arrow {
      display: none;
    }

    .experience-item-selected {
      padding-bottom: 5px;
      border-bottom: 3px solid var(--accent);
    }

    .experience-selector ul {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 40px;
      font-size: 14px;
    }

    .experience-content {
      margin-left: 0;
    }

    .about-content-likes-block {
      flex-direction: column;
    }

    .about-content-like {
      width: 100%;
      margin: 20px 0;
    }

  }
