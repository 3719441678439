.social-media-container {
    display: flex;
    flex-direction: column;
    position: fixed;
    bottom: 115px;
    left: 15px;
  }

  .social-media-container img {
    width: 30px;
    height: 30px;
    margin: 15px;
    transform: scale(1);
    transition: transform 200ms ease-in-out;
  }

  .social-media-container img:hover {
    transform: scale(1.2);
  }

  .social-media-logo-inverted {
    filter: invert(100%);
  }

  @media screen and (max-width: 850px) {

    .social-media-container {
      flex-direction: row;
      position: static;
      width: 100%;
      justify-content: center;
    }

  }